<template>
  <div :class="{open:isOpen, ready}" @click="listToggle" tab>
    <ul class="para" :class="{'sup-tabs':isTab}">
      <li v-for="item in list" :key="item.key" @click="goPage(item.key)" :class="{ active: active === item.key }" >{{ item.label }}</li>
    </ul>
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy'

export default {
  name: "Tab",
  props: {
    list: { type: Array, default: null },
    active: { type: null, default: null }
  },
  data() {
    return {
      isOpen:  false,
      ready:  true,
    }
  },
  mounted() {
    setTimeout(() =>{ this.ready = false },500);
  },
  computed: {
    isTab() {
      return this.$route.path === '/support/qna' || this.$route.path === '/support/reference' || this.$route.path === '/support/tps' || this.$route.path === '/support/engineer';
    },
    orderList() {
      return _sortBy(this.list, item => (item.key === this.active ? 0 : 1))
    }
  },
  methods: {
    listToggle() {
      this.isOpen = !this.isOpen
    },
    goPage(e) {
      if(e === 'engineer' || e === 'tps') { alert('준비중입니다.'); }
      else { this.$emit('select', e)}
    }
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[tab] { transform: translateY(0); transition: transform 0.5s, opacity 0.5s; .ease-out; .bgc(#fff);
  ul { .tc; .rel; .h(101); overflow: hidden; transition: height 0.2s; .ease-out; .rel;
    &:after { .cnt; .wh(53,29); .contain('/images/mo/ico-tab-arrow.png'); .abs; .rt(48,38); }
    li { .fs(30); .lh(70); .bold; .ls(-0.025em); .pointer; .spoqa; .-b(#333); .-box; .rel; .lt(0,100); .wf; color:#0d1112; transition: opacity 0.3s;
      &.active { .fs(36); .lh(100); .abs; .lt; }
      &:hover { .o(1); }
    }
  }
  &.open ul {.h(200);
    &.sup-tabs { .h(315); }
    &:after { transform: rotate(180deg); }
  }
  &.ready { transform: translateY(100px); opacity: 0; }
}
@media screen and(min-width: 1024px) {
  [tab] {
    ul { .h(85); .-b;
      &:after { .cnt; .wh(100%,1); .bgc(#d8d8d8); .abs; .lb(0,5); }
      li { .fs(24); .lh(85); .min-w(247); .max-w(fit-content); .p(0,20);.m(0,25); .ib; .vam; .o(0.2); .rel; left:auto; top:auto; .-b; .-box;
        &.active { .fs(24); .lh(85); .o(1); left:auto; top:auto; .rel; .c(#0079ae);
          &:after { .cnt; .wh(100%,5); .bgc(#0079ae); .abs; .lb; }
        }
      }
    }
    &.open ul { height: auto;
      &.sup-tabs { height: auto; }
    }
  }
}
</style>